/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
section.statistics {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.4em;
}

section.statistics article.stat {
  flex-grow: 1;
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 1px;
  flex-basis: 23%;
  flex-shrink: 0;
}

@media (max-width: 991px) {
  section.statistics article.stat {
    flex-basis: 48%;
  }
}

@media (max-width: 767px) {
  section.statistics article.stat {
    flex-basis: 100%;
  }
}

section.statistics article.stat > img {
  position: absolute;
  top: 0;
  opacity: 0.1;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

section.statistics article.stat:before {
  content: '';
  width: calc(100% + 1px);
  height: 0;
  background: rgba(255, 255, 255, 0.9);
  position: relative;
  padding-top: 100%;
  display: block;
  margin: 0;
}

@media (max-width: 767px) {
  section.statistics article.stat:before {
    padding-top: 70%;
    margin: -1px 0;
  }
}

section.statistics article.stat .align {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  min-width: 160px;
}

section.statistics article.stat .align .icon {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  min-width: 35%;
}

section.statistics article.stat .icon {
  flex: 7em 0 0;
}

section.statistics article.stat .icon img {
  width: 100px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  section.statistics article.stat .icon img {
    max-width: 60px;
    width: 100%;
  }
}

section.statistics article.stat .hgroup {
  margin-left: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

section.statistics article.stat .hgroup .number {
  font-size: 5.2em;
  font-family: "Oswald", sans-serif;
  color: black;
  margin-bottom: 0.15em;
  display: block;
  line-height: 0.9;
  min-width: 1.7em;
}

section.statistics article.stat .hgroup .label {
  font-size: 2.4em;
  color: #8c7253;
  font-size: 2.4em;
  display: block;
  max-width: 7em;
  line-height: 1.1;
  margin-bottom: 0;
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}

.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-default .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
  display: block;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-default .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
  font-family: "Helvetica Neue", sans-serif;
  line-height: 1.1em;
}

.odometer.odometer-auto-theme .odometer-value, .odometer.odometer-theme-default .odometer-value {
  text-align: center;
}
